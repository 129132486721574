<template>
  <div class="autocomplete text-center">
    <!--<input type="text" v-model="search" @input="onChange" />-->
    <floating-field type="text" :id="id" :label="label" v-model="search" :isDisabled="isDisabled" :isRequired="isRequired" :invalidFeedback="errorLabel" :showLabel="showLabel"></floating-field>
    <ul class="autocomplete-results" v-if="(isOpen || isLoading) && items.length">
      <!--<li class="loading" v-if="isLoading">Loading results...</li>
      <template v-if="results[0].employeeId">
        <li v-for="(result, i) in results" :key="i" class="autocomplete-result" @click="setResult(result)">
          {{ result[descriptionField] }}
        </li>
      </template>
      <li v-else>{{  }}</li>-->
      <li v-for="(result, i) in results" :key="i" v-bind:class="{ 'autocomplete-result' : result[idField] }" @click="result[idField] && setResult(result)">
        {{ result[descriptionField] }}
      </li>
    </ul>
  </div>
</template>

<style scoped lang="scss">
  /*.autocomplete {
    position: relative;
    width: 130px;
  }*/

  .autocomplete-results {
    padding: 0;
    margin: 0;
    border: 1px solid #eeeeee;
    max-height: 120px;
    overflow: auto;
  }

  .autocomplete-result {
    list-style: none;
    text-align: left;
    padding: 4px 2px;
    cursor: pointer;
  }

    .autocomplete-result:hover {
      background-color: #0066b2;
      color: white;
    }
</style>

<script>
  import { debounce } from 'lodash'
  import eventBus from '@/utils/eventBus'
  import { EMPLOYEE_SEARCH_STARTED_EVENT, EMPLOYEE_SELECTED_EVENT, EMPLOYEE_SEARCH_CLEARED_EVENT } from '@/utils/constants'

  import FloatingField from '@/components/ui/FloatingField'

  export default {
    name: 'employee-autocomplete',
    components: {
      FloatingField
    },
    props: {
      id: {
        type: String,
        required: false,
        default: 'emp_search'
      },
      label: {
        type: String,
        required: false,
        default: 'name'
      },
      items: {
        type: Array,
        required: false,
        default: () => []
      },
      sData: {
        type: [String, Number, Object, null],
        required: true,
        default: ''
      },
      isShown: {
        type: Boolean,
        required: false,
        default: false
      },
      isRequired: {
        type: Boolean,
        required: false,
        default: false
      },
      isDisabled: {
        type: Boolean,
        required: false,
        default: false
      },
      showLabel: {
        type: Boolean,
        required: false,
        default: true
      },
      errorLabel: {
        type: String,
        required: false,
        default: ''
      },
      employeeSearchStartedEvent: {
        type: String,
        required: false,
        default: EMPLOYEE_SEARCH_STARTED_EVENT
      },
      employeeSelectedEvent: {
        type: String,
        required: false,
        default: EMPLOYEE_SELECTED_EVENT
      },
      employeeSearchClearedEvent: {
        type: String,
        required: false,
        default: EMPLOYEE_SEARCH_CLEARED_EVENT
      },
      idField: {
        type: String,
        required: false,
        default: 'employeeId'
      },
      descriptionField: {
        type: String,
        required: false,
        default: 'fullName'
      }
    },
    data() {
      return {
        search: '',
        results: [],
        isOpen: false,
        isLoading: false,
        isSearchMode: true,
        arrowCounter: -1
      }
    },
    methods: {
      filterResults() {
        this.results = this.items.filter(item => item.toLowerCase().indexOf(this.search.toLowerCase()) > -1)
      },
      setResult(result) {
        this.isSearchMode = false
        this.search = result[this.descriptionField]
        this.isOpen = false
        eventBus.$emit(this.employeeSelectedEvent, result)
      },
      handleClickOutside(evt) {
        if (!this.$el.contains(evt.target)) {
          this.isOpen = false
          this.arrowCounter = -1
        }
      }
    },
    watch: {
      // data (value, oldValue) {
      //  if (value) {
      //    this.search = value;
      //  }
      // },
      //isShown: {
      //  immediate: true,
      //  handler(newValue, oldValue) {
      //    //this.$el.getElementById(this.id).fireEvent('onclick');
      //  }
      //},
      //sData(newValue) {
      //  if (newValue) {
      //    this.isSearchMode = false
      //    this.search = newValue
      //    var evObj = document.createEvent('Events');
      //    evObj.initEvent('focus', true, false);
      //    this.$el.querySelector('#' + this.id).dispatchEvent(evObj);
      //  }
      //  else {
      //    //this.isSearchMode = false
      //    //this.search = ''
      //  }
      //},
      sData: {
        immediate: true,
        handler(newValue, oldValue) {
          if (newValue) {
            this.isSearchMode = false
            this.search = newValue.trim()
            this.$nextTick(() => {
              var evObj = document.createEvent('Events');
              evObj.initEvent('focus', true, false);
              this.$el.querySelector('#' + this.id).dispatchEvent(evObj);
            })
          }
          else {
            this.search = ''
          }
        }
      },

      // Once the items content changes, it means the parent component
      // provided the needed data
      items: function (value, oldValue) {
        // we want to make sure we only do this when it's an async request
        // this.results = value.map(x => {
        //  return {
        //    name: x.employeeName,
        //    id: x.userID
        //  }
        // })
        this.results = value
        this.isOpen = true
        this.isLoading = false
      },
      search: {
        immediate: true,
        handler(newValue, oldValue) {
          if (this.isSearchMode) {
            if (this.onSearch) {
              this.onSearch()
            }
          }
          this.isSearchMode = true
        }
      }
      //search() {
      //  if (this.isSearchMode) {
      //    this.onSearch()
      //  }
      //  //alert(this.search + " ssssssss")
      //  this.isSearchMode = true
      //}
    },
    created() {
      const self = this
      // self.$on("input", ((value) => {
      //  alert("Input")
      // }))
      self.onSearch = debounce(function () {
        if (self.search && self.search.length >= 4) {
          self.isLoading = true
          eventBus.$emit(this.employeeSearchStartedEvent, self.search)
        }
      }, 500)
    },
    mounted() {
      const self = this
      document.addEventListener('click', self.handleClickOutside)
      var input = document.getElementById(this.id);
      input.onkeydown = function () {
        var key = event.keyCode || event.charCode;
        if ((key == 8 || key == 46) && (input.selectionEnd == input.value.length)) {
          self.isSearchMode = true
          self.search = ''
          eventBus.$emit(self.employeeSearchClearedEvent)
        }
      }
    },
    destroyed() {
      document.removeEventListener('click', this.handleClickOutside)
    }
  }
</script>
